import React, { useState } from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import AggregationWithApi from "../assets/images/aggregation-with-api.webp";
import ApiIntegrationProcess from "../assets/images/travelcarma-api-integration-process.webp";
import SuppliersHotel from "../components/suppliers-hotel";
import SuppliersFlight from "../components/suppliers-flight";
import SuppliersActivity from "../components/suppliers-activity";
import SuppliersCar from "../components/suppliers-car";

const ApiTravelXmlApiIntegrations = () => {
  const [activeTab, setActive] = useState("tab1");
  return (
    <div className="page-inside">
      <PageHeader page="travel-xml-api-integrations" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={AggregationWithApi}
              alt="API Integration"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "28px" }}>
              <strong>All you need is an API Contract</strong> with the
              supplier(s) of your choice and we can easily integrate their API
              into the system. This way you can aggregate content from multiple
              suppliers, add your own markups and sell them online through our{" "}
              <Link
                to="/products/booking-engines/flight-booking-engine"
                className="text-decoration-none text-primary"
              >
                booking engines for flight
              </Link>
              ,{" "}
              <Link
                to="/products/booking-engines/car-booking-engine"
                className="text-decoration-none text-primary"
              >
                car rental
              </Link>
              ,{" "}
              <Link
                to="/products/booking-engines/activity-booking-engine"
                className="text-decoration-none text-primary"
              >
                activities
              </Link>
              ,{" "}
              <Link
                to="/products/booking-engines/transfers-booking-engine"
                className="text-decoration-none text-primary"
              >
                transfers
              </Link>{" "}
              and{" "}
              <Link
                to="/products/booking-engines/hotel-booking-engine"
                className="text-decoration-none text-primary"
              >
                hotel booking engine
              </Link>
              .
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "28px" }}>
              <strong>TravelCarma</strong> provides seamless integration with
              all the <strong>GDSs(Global Distribution Systems)</strong> via{" "}
              <strong>XML API</strong>. A GDS acts as a{" "}
              <strong>centralized reservation system</strong> that provides
              real-time fares and availabilities for flights and other travel
              related products to travel agents. It is connected with airline,
              hotel and other supplier systems in the back-end and manages
              distribution of inventory for these providers globally.
              TravelCarma’s <strong>GDS Integration</strong> allows you to offer
              your B2C and B2B clients access to worldwide content on both web
              and mobile interfaces. TravelCarma has already integrated{" "}
              <strong>Amadeus, Sabre and Travelport APIs</strong> for several
              clients.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "28px" }}>
              TravelCarma provides a unified <strong>XML API Interface</strong>{" "}
              wherein you can manage your entire{" "}
              <strong>
                third-party supplier inventory from a single window
              </strong>
              , rather than logging into multiple systems and shuffling through
              various screens to access live fares. This reduces your effort and
              helps you concentrate on selling more.
            </p>

            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <div className="mt-5">
          <ul className="nav nav-tabs mt-5" style={{ marginBottom: "-28px" }}>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab1" ? "active" : "")}
                onClick={() => setActive("tab1")}
              >
                Hotel
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab2" ? "active" : "")}
                onClick={() => setActive("tab2")}
              >
                Flight
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab3" ? "active" : "")}
                onClick={() => setActive("tab3")}
              >
                Activity
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab4" ? "active" : "")}
                onClick={() => setActive("tab4")}
              >
                Transfers
              </button>
            </li>
          </ul>

          {activeTab === "tab1" && <SuppliersHotel />}
          {activeTab === "tab2" && <SuppliersFlight />}
          {activeTab === "tab3" && <SuppliersActivity />}
          {activeTab === "tab4" && <SuppliersCar />}
        </div>
        <br />
        <br />

        <h2 className="text-center mt-5">Our API Integration Process</h2>
        <div className="text-center mt-5">
          <img
            src={ApiIntegrationProcess}
            alt="API Integration Process"
            className="w-100"
            style={{ maxWidth: "1000px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ApiTravelXmlApiIntegrations;
