import React from "react";
import HotelbedsLogo1 from "../assets/images/suppliers/Hotelbeds_Logo_1.webp";
import GTALogo3 from "../assets/images/suppliers/GTA_Logo_3.webp";
import stuba from "../assets/images/suppliers/stuba.webp";
import TravcoLogo5 from "../assets/images/suppliers/Travco_Logo_5.webp";
import TouricoLogo6 from "../assets/images/suppliers/Tourico_Logo_6.webp";
import GoGlobalLogo7 from "../assets/images/suppliers/GoGlobal_Logo_7.webp";
import DestinationsOftheWorldLogo8 from "../assets/images/suppliers/Destinations Of the World_Logo_8.webp";
import RestelLogo9 from "../assets/images/suppliers/Restel_Logo_9.webp";
import Hoteldelogo10 from "../assets/images/suppliers/Hotel.de_logo_10.webp";
import ItalCamelLogo11 from "../assets/images/suppliers/ItalCamel_Logo_11.webp";
import JacTravelLogo12 from "../assets/images/suppliers/JacTravel_Logo_12.webp";
import KuoniLogo13 from "../assets/images/suppliers/Kuoni_Logo_13.webp";
import MikiTravelLogo14 from "../assets/images/suppliers/Miki Travel_Logo_14.webp";
import TravellandaLogo15 from "../assets/images/suppliers/Travellanda_Logo_15.webp";
import Asiatravellogo16 from "../assets/images/suppliers/Asiatravel_logo_16.webp";
import AsianTrailsLogo18 from "../assets/images/suppliers/Asian Trails_Logo_18.webp";
import AmericanToursLogo19 from "../assets/images/suppliers/AmericanTours_Logo_19.webp";
import CleartripLogo20 from "../assets/images/suppliers/Cleartrip_Logo_20.webp";
import Pactobalilogo21 from "../assets/images/suppliers/Pactobali_logo_21.webp";
import DesiyaLogo22 from "../assets/images/suppliers/Desiya_Logo_22.webp";
import ivivulogo23 from "../assets/images/suppliers/ivivu_logo_23.webp";
import MakeMyTripLogo24 from "../assets/images/suppliers/MakeMyTrip_Logo_24.webp";
import ExpediaAffiliateNetworkLogo25 from "../assets/images/suppliers/Expedia Affiliate Network_Logo_25.webp";
import ZumataLogo26 from "../assets/images/suppliers/Zumata_Logo_26.webp";
import TotalStayLogo27 from "../assets/images/suppliers/Total Stay_Logo_27.webp";
import MarkInternationalLogo28 from "../assets/images/suppliers/Mark International_Logo_28.webp";
import TravZillaLogo29 from "../assets/images/suppliers/TravZilla_Logo_29.webp";
import HanaTourLogo30 from "../assets/images/suppliers/Hana Tour_Logo_30.webp";
import TBOHotelsLogo31 from "../assets/images/suppliers/TBO Hotels_Logo_31.webp";
import SabreLogo32 from "../assets/images/suppliers/Sabre_Logo_32.webp";
import GRNConnectLogo33 from "../assets/images/suppliers/GRN Connect_Logo_33.webp";
import TheAOTGroupLogo34 from "../assets/images/suppliers/The AOT Group_Logo_34.webp";
import BonotelLogo35 from "../assets/images/suppliers/Bonotel_Logo_35.webp";
import CyberLogicLogo36 from "../assets/images/suppliers/CyberLogic_Logo_36.webp";
import NTIncomingLogo37 from "../assets/images/suppliers/NTIncoming_Logo_37.webp";
import RealtimeTravelSolutionLogo38 from "../assets/images/suppliers/Realtime Travel Solution_Logo_38.webp";
import SpecialToursLogo39 from "../assets/images/suppliers/SpecialTours_Logo_39.webp";
import TeamAmericaLogo40 from "../assets/images/suppliers/TeamAmerica_Logo_40.webp";
import VeturisLogo41 from "../assets/images/suppliers/Veturis_Logo_41.webp";
import WebHotelierLogo42 from "../assets/images/suppliers/WebHotelier_Logo_42.webp";
import PricelineLogo from "../assets/images/suppliers/PricelineLogo.webp";

const SuppliersHotel = () => {
  return (
    <ul className="suppliers-logos mt-5">
      <li>
        <img src={HotelbedsLogo1} alt="Hotelbeds" />
      </li>
      <li>
        <img src={GTALogo3} alt="GTA" />
      </li>
      <li>
        <img src={stuba} alt="RoomsXML" />
      </li>
      <li>
        <img src={TravcoLogo5} alt="Travco" />
      </li>
      <li>
        <img src={TouricoLogo6} alt="Tourico" />
      </li>
      <li>
        <img src={GoGlobalLogo7} alt="GoGlobal" />
      </li>
      <li>
        <img
          src={DestinationsOftheWorldLogo8}
          alt="Destinations Of the World"
        />
      </li>
      <li>
        <img src={RestelLogo9} alt="Restel" />
      </li>
      <li>
        <img src={Hoteldelogo10} alt="Hotel.de" />
      </li>
      <li>
        <img src={ItalCamelLogo11} alt="ItalCamel" />
      </li>
      <li>
        <img src={JacTravelLogo12} alt="JacTravel" />
      </li>
      <li>
        <img src={KuoniLogo13} alt="Kuoni" />
      </li>
      <li>
        <img src={MikiTravelLogo14} alt="Miki Travel" />
      </li>
      <li>
        <img src={TravellandaLogo15} alt="Travellanda" />
      </li>
      <li>
        <img src={Asiatravellogo16} alt="Asiatravel" />
      </li>
      <li>
        <img src={AsianTrailsLogo18} alt="Asian Trails" />
      </li>
      <li>
        <img src={AmericanToursLogo19} alt="AmericanTours" />
      </li>
      <li>
        <img src={CleartripLogo20} alt="Cleartrip" />
      </li>
      <li>
        <img src={Pactobalilogo21} alt="Pactobali" />
      </li>
      <li>
        <img src={DesiyaLogo22} alt="Desiya" />
      </li>
      <li>
        <img src={ivivulogo23} alt="ivivu" />
      </li>
      <li>
        <img src={MakeMyTripLogo24} alt="MakeMyTrip" />
      </li>
      <li>
        <img
          src={ExpediaAffiliateNetworkLogo25}
          alt="Expedia Affiliate Network"
        />
      </li>
      <li>
        <img src={ZumataLogo26} alt="Zumata" />
      </li>
      <li>
        <img src={TotalStayLogo27} alt="Total Stay" />
      </li>
      <li>
        <img src={MarkInternationalLogo28} alt="Mark International" />
      </li>
      <li>
        <img src={TravZillaLogo29} alt="TravZilla" />
      </li>
      <li>
        <img src={HanaTourLogo30} alt="Hana Tour" />
      </li>
      <li>
        <img src={TBOHotelsLogo31} alt="TBO Hotels" />
      </li>
      <li>
        <img src={SabreLogo32} alt="Sabre" />
      </li>
      <li>
        <img src={GRNConnectLogo33} alt="GRN Connect" />
      </li>
      <li>
        <img src={TheAOTGroupLogo34} alt="The AOT Group" />
      </li>
      <li>
        <img src={BonotelLogo35} alt="Bonotel" />
      </li>
      <li>
        <img src={CyberLogicLogo36} alt="CyberLogic" />
      </li>
      <li>
        <img src={NTIncomingLogo37} alt="NTIncoming" />
      </li>
      <li>
        <img
          src={RealtimeTravelSolutionLogo38}
          alt="Realtime Travel Solution"
        />
      </li>
      <li>
        <img src={SpecialToursLogo39} alt="SpecialTours" />
      </li>
      <li>
        <img src={TeamAmericaLogo40} alt="TeamAmerica" />
      </li>
      <li>
        <img src={VeturisLogo41} alt="Veturis" />
      </li>
      <li>
        <img src={WebHotelierLogo42} alt="WebHotelier" />
      </li>
      <li>
        <img src={PricelineLogo} alt="PriceLine" />
      </li>
    </ul>
  );
};

export default SuppliersHotel;
