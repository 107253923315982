import React from "react";
import { Link } from "react-router-dom";
import Blog1 from "../assets/images/home-blog-post-1.webp";
import Blog2 from "../assets/images/home-blog-post-2.webp";
import Blog3 from "../assets/images/home-blog-post-3.webp";

const HomeBlog = () => {
  return (
    <div className="home-blog mt-5">
      <div className="container text-center">
        <h2 className="text-center mb-5">Recent posts from our Blog</h2>

        <div className="row">
          <div className="col-lg-4 mb-4">
            <div className="home-blog-box">
              <img
                src={Blog1}
                alt="How to Explore the World During the Pandemic"
                className="w-100"
              />
              <div className="p-4">
                <h5 className="mb-3">
                  How to Explore the World During the Pandemic
                </h5>
                <p className="small text-secondary">
                  Even though nothing beats seeing some of the best attractions
                  in person, the pandemic has forced people to change the way
                  they do things. With the strict traveling guidelines and new
                  strains of the...
                </p>
                <Link
                  to="https://blog.travelcarma.com/2021/06/21/how-to-explore-the-world-during-the-pandemic/"
                  target="_blank"
                  className="btn btn-sm btn-primary mt-2"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div className="home-blog-box">
              <img
                src={Blog2}
                alt="How to Get Your Travel Business Ready for the Post-Pandemic Boom"
                className="w-100"
              />
              <div className="p-4">
                <h5 className="mb-3">
                  How to Get Your Travel Business Ready for the Post-Pandemic
                  Boom
                </h5>
                <p className="small text-secondary">
                  With lockdown restrictions easing in many parts of the world
                  and borders reopening, there is a renewed sense of optimism in
                  the industry.&nbsp; Travel searches on Google and online
                  portals have started going up,...
                </p>
                <Link
                  to="https://blog.travelcarma.com/2021/06/14/how-to-get-your-travel-business-ready-for-the-post-pandemic-boom/"
                  target="_blank"
                  className="btn btn-sm btn-primary mt-2"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div className="home-blog-box">
              <img
                src={Blog3}
                alt="Travel Technology in a Post-COVID World"
                className="w-100"
              />
              <div className="p-4">
                <h5 className="mb-3">
                  Travel Technology in a Post-COVID <br />
                  World
                </h5>
                <p className="text-secondary small">
                  The effects of the worldwide virus outbreak and widespread
                  lockdown have plunged the global economy into a crisis. The US
                  economy was predicted to contract by 6.1% this year, while the
                  worldwide economy was forecasted...
                </p>
                <Link
                  to="https://blog.travelcarma.com/2021/01/28/travel-technology-in-a-post-covid-world/"
                  target="_blank"
                  className="btn btn-sm btn-primary mt-2"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
        <h4 className="mt-2">
          Interested in contributing guest articles to our blog?{" "}
          <Link
            to="https://blog.travelcarma.com"
            target="_blank"
            className="text-primary"
          >
            Get in touch today!
          </Link>
        </h4>
      </div>
    </div>
  );
};

export default HomeBlog;
