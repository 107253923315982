import React from "react";
import SupplierLogos from "../assets/images/supplier-logos.webp";
import { Link } from "react-router-dom";

const HomeSuppliers = () => {
  return (
    <div className="home-suppliers">
      <div className=" container">
        <div className="text-center position-relative" style={{ zIndex: "2" }}>
          <h2 className="text-center mb-5 text-white">
            Integrate Global Content
          </h2>
          <div>
            <img
              src={SupplierLogos}
              className="w-100 mb-2"
              style={{ maxWidth: "1162px" }}
              alt="Travel supplier API Integration"
            />
          </div>
          <Link
            to="/suppliers/flight-api-integration"
            className="btn btn-primary mt-4"
          >
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeSuppliers;
