import React from "react";
import PageHeader from "../components/page-header";
import Logo1 from "../assets/images/customers/testimonials/Gotadi_testimonial.webp";
import Logo2 from "../assets/images/customers/testimonials/Travelshop_testimonial.webp";
import Logo3 from "../assets/images/customers/testimonials/SmartTravel_testimonial.webp";
import Logo4 from "../assets/images/customers/testimonials/AtsaViajes-testimonial.webp";
import Logo5 from "../assets/images/customers/testimonials/Buspay_testimonial.webp";
import Logo6 from "../assets/images/customers/testimonials/geont_testimonial.webp";
import Logo7 from "../assets/images/customers/testimonials/GCS_testimonial.webp";
import Logo8 from "../assets/images/customers/testimonials/Sun_Tours_testimonial.webp";
import Logo9 from "../assets/images/customers/testimonials/Touring_Greenland_testimonial.webp";
import Logo10 from "../assets/images/customers/testimonials/ElSolVida_testimonial.webp";
import Logo11 from "../assets/images/customers/testimonials/debdab_testimonial.webp";
import Logo12 from "../assets/images/customers/testimonials/Four_Square_testimonial.webp";
import Logo13 from "../assets/images/customers/testimonials/Incentours_testimonial.webp";
import Logo14 from "../assets/images/customers/testimonials/alfanar_testimonial.webp";
import Logo15 from "../assets/images/customers/testimonials/myhotels-testimonial.webp";

const CustomerTestimonials = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="customer-testimonials" />
      <div className="container">
        <div className="text-center">
          <p>
            We have a proven track record of enabling hundreds of travel
            businesses across the world attain their business goals, whether
            it's getting online, automating their bookings, reducing their
            operational costs or maximizing their revenue potential. Here's what
            some of our satisfied customers have to say about us.
          </p>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo1} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We selected TravelCarma after rigorous vendor selection process.
                We needed a lot of modules from flight, hotels, tours and
                activities to unique ones like Free and Easy bookings. The
                challenge was to aggregate from several XML suppliers,
                especially with ones with primitive technologies.
              </p>
              <p className="text-secondary">
                We communicated our plans and ideas to TravelCarma, and their
                experienced team rapidly brainstormed all the cases, asked
                questions, shared knowledge and sometimes argued and finally
                provided the most accepted solution.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo2} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                After checking out major hospitality technology solutions
                providers in the world, we have chosen TravelCarma as most
                affordable solution for our business as best value for money
                system. One of the most important benefits that we received with
                TravelCarma is great number of pre-integrated suppliers so we
                were able to connect in great speed and start our business
                without delay. With forthcoming and most professional personnel
                that always stand at your disposal we highly recommend
                TravelCarma as amongst the best in travel technology.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo3} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We are a new established company entering into the travel
                industry. TravelCarma has not just provided us with knowledge
                beyond the understanding of the systems but furthermore an
                information and advice about the travel business. We are
                Satisfied with the service and excellent software system of
                TravelCarma. The relationship we have with our account manager
                throughout communications gives us reassurance that all our
                support needs are dealt with promptly.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo4} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We have been using TravelCarma for a few months now and their
                attention to detail both before and after the system delivery
                has been just amazing. We always get a fast response to all our
                questions and they are very easy to work with.
              </p>
              <p className="text-secondary">
                Our productivity has gone up considerably since we started using
                their system. Their team has helped plug the holes in our
                business processes and they have streamlined our business to a
                large degree. Moreover, their price is absolutely great
                considering the value they provide, as compared to other vendors
                in the market which don't offer the same value for money and can
                be quite difficult to work with.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo5} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                After a process of evaluating several vendors, and the solvency
                and experience we received from the sites that use TravelCarma
                were the factors that made us select TravelCarma for our Online
                Bus Reservation Portal needs. TravelCarma was a very fast in
                implementing the application. Most of it was plug and play
                software application.
              </p>
              <p className="text-secondary">
                TravelCarma has a great capacity to adapt. In our project, the
                challenge was to make it to operate in the Latin American
                market. This goal was quickly achieved by TravelCarma by
                customizing the application in a very short time. For our
                business, TravelCarma intrinsically brought business rules that
                streamlined the operations of carriers of passengers. I strongly
                recommend TravelCarma.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo6} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                I am delighted to share with you how great experience this has
                been working with you guys so far. After several training
                sessions with your staff, I can tell you that I'm really
                impressed with the quality and depth of your solution, as well
                as the professional support we have been receiving from all your
                team members. I really enjoy the professional setup, the
                training methodology and personal touch. I will not hesitate to
                recommend you and look forward to start operating.
              </p>
              <p className="text-secondary">
                A particular recognition to the account manager and
                implementation manager who have gone beyond our expectations to
                setup and navigate us through the whole process. Look forward to
                many years of collaboration and great experience for our
                customers and agents.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo7} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                I would like to first thank TravelCarma for helping us realize
                our dream of creating the first dynamic hotel reservations
                portal in west of Africa.
              </p>
              <p className="text-secondary">
                TravelCarma has not only been very helpful, but very prompt in
                responding to our queries. Their support accessibility is
                phenomenal despite of the distance between us. Whenever I called
                for help I surely knew it was on its way. I found it easy to
                talk to them rather than other providers that I dealt with. Our
                account manager was not only very friendly, but also made us
                feel special every time we reached out.
              </p>
              <p className="text-secondary">
                I would definitely recommend TravelCarma to anybody from
                personal experience. In the sense of understanding how the
                business works, they are always there to give you tips and
                guidance. I am very glad today that I choose to work with them.
                They are very trustworthy.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo8} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                TravelCarma has proven to be a valuable resource for our
                company. The features and flexibility of the product along with
                the expert advice and support from the TravelCarma team has
                allowed us to drastically improve the efficiency of our
                reservation processes. We have been using TravelCarma for over
                two years now and the process has been simple and painless to
                switch from our previous systems.
              </p>
              <p className="text-secondary">
                Customer service from TravelCarma has been very good and what
                excites us the most is the potential to further streamline our
                administration and ultimately help deliver better service to our
                customers as we further integrate our operation with
                TravelCarma.
              </p>
              <p className="text-secondary">
                One of the benefits we were rewarded with was of not having to
                jump in the deep end from the start, allowing us to adapt to our
                procedures over time with the system, which has been seamless to
                date.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo9} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We are a travel company in Greenland trying to gather all the
                tourist activities nationwide in one website. We found it really
                difficult to find ONE system that was able to plan
                accommodation, activities, and flight and boat trips. A lot of
                the programs we looked into were strong in one area and weak in
                the others; the few systems strong on all areas were too
                expensive for us. So we were very pleased when we found
                TravelCarma, it is affordable and it can handle all the above
                mentioned criteria. And it is easy to navigate in the backend
                even for the complex functionalities. Beside this they have a
                great service and a great energy!
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo10} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We engaged TravelCarma to provide us with a B2C platform to
                enhance our ability to offer our customers an easy way to find,
                review and book our tours and other services. We are now
                enjoying the functionality and benefits of the platform such as,
                organizing of our tours, easy tracking and multiple automatic
                reports among other benefits. Being thousands of miles away,
                support at TravelCarma is second to none as daily conversations
                and ideas exchange makes us feel like TravelCarma is the IT
                department in my business. We continue to grow with TravelCarma.
                You Too Can! Get ahead with TravelCarma.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo11} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We have been using TravelCarma for over 5 years for serving our
                large retail customer and agent base. They provide a really
                useful and scalable booking platform for flights, hotels and
                activities, developed after a lot of research in the field of
                tourism and includes several travel management features required
                by companies like ours.
              </p>
              <p className="text-secondary">
                TravelCarma has a great team that is most helpful and quick to
                respond and service our needs. They have often gone out of their
                way to support us which is why we have stuck with them for so
                long. We strongly recommend them over other providers.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo12} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                The new version of the booking system of TravelCarma with
                various functions is great. The quotations manager, hotels
                de-duplication, and the map based search options are so strong
                functions from B2B business prospective. Their B2B system is
                easy to understand and saves a lot of time of our agents. I
                gambled peanuts and got a Boeing 407.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo13} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                I've worked with many, many technology companies over the years,
                and I would rank TravelCarma as one of the most insightful and
                strategic teams with whom I've partnered.
              </p>
              <p className="text-secondary">
                I have been extremely impressed with TravelCarma. In a highly
                specialized technology industry, they seized quickly on the
                opportunities and issues in our business and put together a
                comprehensive plan for our OTA needs. Their high-energy and
                analytical skills allowed them to present to us a completely
                different way of looking at one of our business segments, and we
                are now leveraging their talent across the rest of our company.
                We are very pleased with their results!
              </p>
              <p className="text-secondary">
                I can definitely say that I'd recommend TravelCarma to anyone.
                The team was critical to us dissecting the consumer insights to
                be able to develop a new co-brand platform that will allow us to
                grow beyond our current space. Additionally, their knowledge of
                travel domain and end users has been extremely valuable in our
                development of the OTA. Overall, I really enjoy working with
                TravelCarma team. They helped us create great value for our
                clients.
              </p>
            </div>
          </div> */}
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo14} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                We have been using TravelCarma for over 3 years and we will
                definitely recommend it to other agencies. Travelcarma is a
                modern Travel Agency Software with all the features a Travel
                Agency needs for driving their online business smoothly and
                efficiently.
              </p>
              <p className="text-secondary">
                We have been using their system for Hotels and soon we are
                planning to go ahead with flights. TravelCarma has strong
                expertise in XML API integration and they have a large number of
                suppliers connected with their system. Their solutions are
                really helpful for any travel agency in managing their entire
                business online.
              </p>
            </div>
          </div>
          <div className="col-lg-6 pb-4">
            <div className="testimonial-box">
              <img src={Logo15} alt="" className="mb-3 rounded" />
              <p className="text-secondary">
                TravelCarma played a major role in launching our brand. They
                provided amazing technical assistance and built our website from
                scratch. They also fulfilled all our complicated and custom
                requirements. Their team responds fast and welcomes new
                requirement requests with amazing delivery. Their support team
                is proactive and on many occasions have fixed issues even before
                we reported them. They provide everything an Online Travel
                Agency needs at an affordable price.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTestimonials;
