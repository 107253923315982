import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EbookThumb1 from "../assets/images/resources/ebooks/ebook-thumb-1.webp";
import EbookThumb2 from "../assets/images/resources/ebooks/ebook-thumb-2.webp";
import EbookThumb3 from "../assets/images/resources/ebooks/ebook-thumb-3.webp";
import EbookThumb4 from "../assets/images/resources/ebooks/ebook-thumb-4.webp";
import EbookThumb5 from "../assets/images/resources/ebooks/ebook-thumb-5.webp";
import PDFEbook1 from "../assets/images/resources/ebooks/Travel-Agents-Guide-to-Customer-Engagement-Online.pdf";
import PDFEbook2 from "../assets/images/resources/ebooks/Travel-Agents-Guide-to-IATA-Certification.pdf";
import PDFEbook3 from "../assets/images/resources/ebooks/Why-Travel-Companies-Should-Never-Ignore-Reviews.pdf";
import PDFEbook4 from "../assets/images/resources/ebooks/Travel-Agents-Guide-to-Customer-Engagement-Online-2.pdf";
import PDFEbook5 from "../assets/images/resources/ebooks/Saas-vs-On-premise-online-booking-system.pdf";

const EbookBox = (props) => {
  const [eb, setEbook] = useState();

  const data = [
    {
      id: "EB1",
      thumb: EbookThumb1,
      title: "How Travel Agencies can Engage Customer Online",
      download: PDFEbook1,
    },
    {
      id: "EB2",
      thumb: EbookThumb2,
      title: "Travel Agent Guide",
      download: PDFEbook2,
    },
    {
      id: "EB3",
      thumb: EbookThumb3,
      title: "Travel and Hospitality Reviews",
      download: PDFEbook3,
    },
    {
      id: "EB4",
      thumb: EbookThumb4,
      title: "Social Media Use in Travel Agencies",
      download: PDFEbook4,
    },
    {
      id: "EB5",
      thumb: EbookThumb5,
      title: "SaaS vs On Premise",
      download: PDFEbook5,
    },
  ];

  const getData = () => {
    let box = data.find((x) => x.id === props?.id);
    setEbook(box);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="text-center">
      <figure className="m-0 p-0">
        <img
          src={eb?.thumb}
          alt={eb?.title}
          className="rounded"
          style={{ maxWidth: "142px" }}
        />
      </figure>
      <Link className="btn btn-primary mt-4" to={eb?.download} target="_blank">
        Download
      </Link>
    </div>
  );
};

export default EbookBox;
