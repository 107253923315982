import React from "react";
import TravelCarmaPlatform from "../assets/images/travelcarma-platform.webp";

const HomePlatform = () => {
  return (
    <div className="home-platform mt-5 pb-5 pt-3">
      <div className="container">
        <h2 className="text-center mb-4">Manage Everything on One Platform</h2>
        <img
          src={TravelCarmaPlatform}
          className="w-100"
          style={{ maxWidth: "1200px" }}
          alt="Travel Technology Platform"
        />
      </div>
    </div>
  );
};

export default HomePlatform;
