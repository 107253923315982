import React from "react";
import PageHeader from "../components/page-header";
import HotelBookingEngineClip from "../assets/images/products/hotel-booking-engine-clipart.webp";
import ImageSlider from "../components/image-slider";
import { Link } from "react-router-dom";

const ProductsHotelBookingEngine = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="hotel-booking-engine" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={HotelBookingEngineClip}
              alt="TravelCarma Hotel Booking Engine"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.16rem", lineHeight: "36px" }}>
              More people are choosing to search and book hotel rooms online
              than ever before, especially after the pandemic. Does your travel
              business have the right solution to meet that demand?
            </p>

            <p style={{ fontSize: "1.16rem", lineHeight: "36px" }}>
              With our hotel reservation system, you can aggregate hotel
              inventory from multiple sources (direct contracts / GDS / bedbanks
              / channel managers) to offer your customers the best possible
              rates.
            </p>

            <p style={{ fontSize: "1.16rem", lineHeight: "36px" }}>
              The aggregated inventory can be booked online using real-time
              rates and availability by your retail customers, B2B agents and
              corporates in just a few clicks.
            </p>

            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5">Top Benefits for your Business</h2>

        <div className="row text-center benefits-section">
          <div className="col-lg-6">
            <h2>A hotel booking solution you can count on</h2>
            <ul className="list-sm-arr">
              <li>
                Available on both web and mobile (responsive and native app)
              </li>
              <li>
                Hosted on AWS cloud to provide unmatched data security, 99.9%
                uptime and accessibility from any device, anywhere, anytime
              </li>
              <li>
                Supports multiple sales channels - B2C, B2B, B2B2C, B2E and
                Corporate
              </li>
              <li>
                Automatic report generation and accounting reconciliation for
                better decision making
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS1" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS2" />
          </div>
          <div className="col-lg-6">
            <h2>Provide your clients a superior search experience</h2>
            <ul className="list-sm-arr">
              <li>
                Multiple search filters and sorting options including price,
                currency, location, star rating, amenities, type, customer
                rating etc
              </li>
              <li>Map-based search to find hotels in a particular area</li>
              <li>
                Ability to see restaurants and attractions near a hotel on the
                map
              </li>
              <li>Ability to set up price alerts from search results</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Minimize clicks to booking</h2>
            <ul className="list-sm-arr">
              <li>Ability to login with Facebook and Google</li>
              <li>
                Ability to add multiple rooms to a shopping cart and book them
                together
              </li>
              <li>
                Ability to store frequent co-traveler details to save time
                during booking
              </li>
              <li>Ability to create wishlists and save hotels to favourites</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS3" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS4" />
          </div>
          <div className="col-lg-6">
            <h2>Manage all your reservations at one place</h2>
            <ul className="list-sm-arr">
              <li>
                User-friendly interface for centralized customer and booking
                management
              </li>
              <li>Automatic hotel voucher generation with your own branding</li>
              <li>
                Auto cancellation of unconfirmed bookings before the
                cancellation period starts
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Offer Flexible Payment Options</h2>
            <ul className="list-sm-arr">
              <li>Multiple payment modes at checkout</li>
              <li>Book-now-pay-later feature</li>
              <li>
                Support for multiple currencies with real-time currency
                conversion
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS5" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS6" />
          </div>
          <div className="col-lg-6">
            <h2>Integrate and Manage your Hotel Inventory</h2>
            <ul className="list-sm-arr">
              <li>
                Ability to set up properties, rooms types, room rates,
                availability and other details in the inventory management
                module
              </li>
              <li>
                Aggregation of multiple hotel XML feeds along along with
                directly contracted inventory into a single search interface
              </li>
              <li>Integration with your existing PMS and Channel Managers</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Customize Pricing and Promotions</h2>
            <ul className="list-sm-arr">
              <li>Separate markups and commission setup for B2C and B2B</li>
              <li>
                Ability to show hotel promotions and advertisements in search
                results
              </li>
              <li>
                Dynamic packaging to book hotel rooms with flight(s) as well as
                with fixed packages
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="HBS7" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsHotelBookingEngine;
