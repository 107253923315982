import React, { useEffect, useState } from "react";
import SliderData from "../components/slider-data";
import ArrowNext from "../assets/images/slider-arw-next.webp";
import ArrowPrev from "../assets/images/slider-arw-prev.webp";

const ImageSlider = (props) => {
  const [slider, setSlider] = useState([]);
  const [current, setCurrent] = useState(0);
  const [currentImg, setCurrentImg] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");

  const getData = () => {
    let slider = SliderData.find((x) => x.name === props?.slider)?.imgs;
    setSlider(slider);
    getCurrent(slider);
  };

  const getCurrent = (slider) => {
    let currentImg = slider?.length > 0 && slider[current]?.img;
    let currentTitle = slider?.length > 0 && slider[current]?.title;
    setCurrentImg(currentImg);
    setCurrentTitle(currentTitle);
  };

  const slideImage = (action) => {
    if (action === "next") {
      let newCurrent = slider?.length === current + 1 ? 0 : current + 1;
      let currentImg = slider?.length > 0 && slider[newCurrent]?.img;
      let currentTitle = slider?.length > 0 && slider[newCurrent]?.title;
      setCurrent(newCurrent);
      setCurrentImg(currentImg);
      setCurrentTitle(currentTitle);
    } else {
      let newCurrent = current === 0 ? slider?.length - 1 : current - 1;
      let currentImg = slider?.length > 0 && slider[newCurrent]?.img;
      let currentTitle = slider?.length > 0 && slider[newCurrent]?.title;
      setCurrent(newCurrent);
      setCurrentImg(currentImg);
      setCurrentTitle(currentTitle);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="position-relative w-100">
      <div
        className={
          "shadow-sm rounded " +
          (props.sliderType !== "mobile"
            ? "product-slider"
            : "product-slider-mobile")
        }
      >
        <div className="product-slider-wrap rounded">
          <img src={currentImg || ""} alt={currentTitle || ""} />
        </div>
      </div>

      {slider?.length > 1 && (
        <div className="text-center">
          <button
            className="product-slider-btn"
            onClick={() => slideImage("prev")}
          >
            <img src={ArrowPrev} alt="Previous" />
          </button>
          <button
            className="product-slider-btn"
            onClick={() => slideImage("next")}
          >
            <img src={ArrowNext} alt="Next" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
