import React, { useState } from "react";
import PageHeader from "../components/page-header";
import EimdpClipart from "../assets/images/solutions/eimdp-clipart.webp";
import EimdpBox from "../assets/images/solutions/eimdp-box.webp";
import EimdpWorkflow from "../assets/images/solutions/eimdp-workflow.webp";
import EdpDiagram from "../assets/images/solutions/edp-diagram.webp";
import { Link } from "react-router-dom";
import SvgIcons from "../components/svg-icons";
import EimdpBrochure from "../assets/images/solutions/eimdp-brochure.webp";
import EimdpPdf from "../assets/resources/EIMDPBrochure.pdf";

const SolutionsInventoryDistribution = () => {
  const [activeTab, setActive] = useState("tab1");
  const [activeTabN, setActiveN] = useState("tab4");
  const [activeAcd, setAcd] = useState();

  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="inventory-distribution" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={EimdpClipart}
              alt="TravelCarma Flight Booking Engine"
              style={{ width: "462px", marginTop: "36px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              TravelCarma{" "}
              <strong>
                E-IMDP(Enterprise Inventory Distribution Platform)
              </strong>{" "}
              is a <strong>cloud-based Travel Distribution Platform</strong>{" "}
              that provides B2B Wholesalers, Suppliers and GSAs the ability to
              manage and distribute contracted inventories across multiple
              online channels.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              <strong>Travel wholesalers</strong> with directly contracted
              inventory of hotels, activities, sightseeing and ground
              transportation can load all their products into TravelCarma
              Inventory systems, or simply integrate their existing CRSs with
              our platform. They can also integrate their third-party feeds from
              GDSs or other B2B providers into the platform and distribute the
              aggregated inventory through a{" "}
              <strong>unified RESTful API</strong> to OTAs and other resellers.
            </p>
            <button className="btn btn-primary">Request a Demo</button>
          </div>
        </div>

        <h2 className="text-center mt-5 mb-5">
          Advantages of our Platform for your business
        </h2>

        <div className="solution-highlights pb-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                      fill="#444444"
                    ></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </figure>

                <h4 className="mb-0">
                  Customizable front-ends and workflows using our development
                  APIs
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Flexibility to separate the database(s) & host them on your
                  own cloud-server
                </h4>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <img
                style={{ maxWidth: "84%" }}
                src={EimdpBox}
                alt="Travel Agency System"
              />
              <button className="btn btn-primary mt-3">Request a Demo</button>
            </div>
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 20 20"
                    style={{ marginTop: "-10px", marginLeft: "-5px" }}
                  >
                    <path
                      d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Serverless environment to control bandwidth & cost based on
                  traffic
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                      fill="#444444"
                    ></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Integration with your existing IT infrastructure via API
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-center pb-4 pt-5"
        style={{ background: "rgba(0,0,0,0.05)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <img
                src={EimdpWorkflow}
                className="w-100 rounded"
                alt="Distribution Channel Management"
              />
              <h4 className="mt-4">
                E-IMDP can pull and push contracted inventory data into OTAs
              </h4>
              <button className="btn btn-primary mt-3">Get in Touch</button>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mt-4 mb-5">Components</h2>
        <ul className="shadow-box list-unstyled row text-center mb-5">
          <li className="col-lg-4">
            <div>
              {SvgIcons?.customer_class_wise_pricing}
              <h3>Central Reservation Systems</h3>
              <p className="text-secondary">
                Through our multi-tenant{" "}
                <strong>
                  Inventory Systems for Hotel, Transport/Transfer, Catering,
                  Ground Services and Sightseeing, B2B Wholesalers
                </strong>{" "}
                can upload their inventory and configure rates, availabilities
                and allocations.
              </p>
            </div>
          </li>
          <li className="col-lg-4">
            <div>
              {SvgIcons?.channel_management}
              <h3>Real-time Channel Manager</h3>
              <p className="text-secondary">
                The Engine is connected to each CRS for to help Wholesalers
                manage different <strong>distribution channels</strong> like{" "}
                <strong>OTAs</strong> and/or external agents. This allows{" "}
                <strong>Travel Distributors</strong> to setup and control their
                distribution to different sales channels with advanced business
                rules.
              </p>
            </div>
          </li>
          <li className="col-lg-4">
            <div>
              {SvgIcons?.global_touch}
              <h3>Data Exchange Switch</h3>
              <p className="text-secondary">
                The switch provides <strong>B2B Wholesalers</strong> a secure
                gateway through which authorized OTAs across the globe can
                access inventories of all the service providers affiliated with
                them via a Unified API. It also allows them to seamlessly
                integrate third-party and legacy systems.
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div
        className="text-center pb-3 pt-4 mb-5"
        style={{ background: "rgba(0,0,0,0.05)" }}
      >
        <div className="container">
          <h2 className="text-center mt-4 mb-5">
            Manage Everything on a Single Platform
          </h2>

          <ul className="shadow-box list-unstyled row text-center mb-5">
            <li className="col-lg-3">
              <div>
                {SvgIcons?.package_quote_creation}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Manage contracts for hotels, sightseeing, ground
                  transportation etc.
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.channel_management}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Manage distribution channels
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.time_cost_savings}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Manage markups and pricing for each channel
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.global_touch}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Monitor reservations and cancellations
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mt-4 mb-4">Solution Architecture</h2>
        <img src={EdpDiagram} className="w-100" alt="Solution Architecture" />
      </div>

      <div
        className="text-center pb-3 pt-4 mb-5 mt-5"
        style={{ background: "rgba(0,0,0,0.05)" }}
      >
        <div className="container">
          <h2 className="text-center mt-4 mb-5">
            Key Benefits for your Business
          </h2>

          <ul className="shadow-box list-unstyled row text-center mb-5">
            <li className="col-lg-3">
              <div>
                {SvgIcons?.customer_class_wise_pricing}
                <h3>Centralized Contract Management</h3>
                <p className="text-secondary">
                  Streamline your API consumers and allocation blocks along with
                  rate management for each distribution channel
                </p>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.channel_management}
                <h3>Insightful Log Management</h3>
                <p className="text-secondary">
                  Manage all your request and response logs. This will provide
                  you a base for having big data analysis and making marketing
                  and contracting decisions
                </p>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.global_touch}
                <h3>Simplified Administration</h3>
                <p className="text-secondary">
                  Centralized admin access for controlling Inventory Supplier
                  and API consumer access and management
                </p>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.resource_optimization}
                <h3>Unified Connectivity</h3>
                <p className="text-secondary">
                  Combined unified API output which allows consumer to integrate
                  single API allowing distribution of all offline inventory
                  providers
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mt-4 mb-5">
          Manage your Contracted Inventory
        </h2>

        <p className="text-center">
          The CRS is essentially an <strong>inventory management system</strong>
          , providing rates and availability for many different channels such as
          the <strong>GDS</strong>, 3rd party websites and brand websites etc.
          Among other functionalities, the CRS allows operators to control rates
          and availabilities in the distribution channels.
        </p>

        <ul className="nav nav-tabs mt-5">
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab1" ? "active" : "")}
              onClick={() => setActive("tab1")}
            >
              Hotel CRS
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab2" ? "active" : "")}
              onClick={() => setActive("tab2")}
            >
              Activity/Excursion CRS
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab3" ? "active" : "")}
              onClick={() => setActive("tab3")}
            >
              Transfers CRS
            </button>
          </li>
        </ul>

        {activeTab === "tab1" && (
          <div className="mt-4">
            <p>
              The <Link>Hotel CRS</Link> is a complete web-based solution
              developed for seamless online reservation, communication for{" "}
              <strong>Online Hotel booking business</strong>. It allows the
              CRS/Property owner to manage their business from a single
              interface. The Hoteliers can configure their business and manage
              their complete inventory in the CRS.
            </p>
            <div className="row">
              <div className="col-lg-8">
                <h4 className="mb-3">Features</h4>
                <h5 onClick={() => setAcd("acd1")}>
                  Property Description &amp; Room Allocation
                </h5>
                {activeAcd === "acd1" && (
                  <ul>
                    <li>
                      <div>
                        Defining property, room types, property amenities, room
                        amenities etc.
                      </div>
                    </li>
                    <li>
                      <div>
                        Upload photos of the property, rooms and amenities
                      </div>
                    </li>
                    <li>
                      <div>
                        Configure room types and room allocation for selling
                        online
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd2")}>Rate Management</h5>
                {activeAcd === "acd2" && (
                  <ul>
                    <li>
                      <div>
                        Periodic rate configuration for different seasons,
                        events, festivals etc.
                      </div>
                    </li>
                    <li>
                      <div>
                        Rates can be defined specific to the nationality of your
                        customer
                      </div>
                    </li>
                    <li>
                      <div>
                        Enterprise CRS for Hotels along with rate management
                        using rate code
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd3")}>
                  Promotion/Offer Management
                </h5>
                {activeAcd === "acd3" && (
                  <ul>
                    <li>
                      <div>
                        Set up promotion/ offers for a specific period with
                        exception criteria
                      </div>
                    </li>
                    <li>
                      <div>
                        Criteria includes % off, fixed discounts, free nights
                        and value added promotions
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd4")}>Policy Management</h5>
                {activeAcd === "acd4" && (
                  <ul>
                    <li>
                      <div>
                        Set up different policies by providing their respective
                        details and apply different parameters
                      </div>
                    </li>
                    <li>
                      <div>
                        Policies include payment policies, cancellation
                        policies, tax policy, child policy, property policies
                        with terms &amp; conditions
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd5")}>Role Management</h5>
                {activeAcd === "acd5" && (
                  <ul>
                    <li>
                      <div>
                        User can easily manage various details for their hotel
                        property
                      </div>
                    </li>
                    <li>
                      <div>
                        Create users at operational levels who have rights to
                        manage their own as well as their subordinate user's
                        hotel inventory
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd6")}>Reports &amp; Dashboards</h5>
                {activeAcd === "acd6" && (
                  <ul>
                    <li>
                      <div>
                        CRS displays a Dashboard, which shows the current status
                        of business in graphical way
                      </div>
                    </li>
                    <li>
                      <div>
                        User can view various transaction dynamic details on the
                        Dashboard with specific filters based on customized
                        transactional criteria's
                      </div>
                    </li>
                    <li>
                      <div>
                        Business Reports includes business details report,
                        revenue reports, product wise revenue reports, billing
                        reports, daily arrival reports, reservation details
                        reports, and cancellation reports, billing details
                        reports, inventory reports, business rate reports and
                        nationality rate reports etc.
                      </div>
                    </li>
                  </ul>
                )}

                <h5 onClick={() => setAcd("acd7")}>Other Features</h5>
                {activeAcd === "acd7" && (
                  <ul>
                    <li>
                      <div>
                        Stop Sell: Set generic stop sell dates and promotional
                        blackout dates for an inventory to make it unavailable
                        for online booking
                      </div>
                    </li>
                    <li>
                      <div>
                        Notifications that are sent automatically in the event
                        of booking, room allocation selling out, cancellation
                        etc.
                      </div>
                    </li>
                    <li>
                      <div>
                        Integrated Google Map to set longitude and latitude for
                        the location
                      </div>
                    </li>
                    <li>
                      <div>
                        Ability to book large number of rooms of one hotel
                        through API in single request
                      </div>
                    </li>
                    <li>
                      <div>
                        Connector - integration with interface of Enterprise
                        Distribution Platform
                      </div>
                    </li>
                    <li>
                      <div>
                        CRS will provide volume based net rates per transaction
                        along with rate code based pricing and discounting.
                      </div>
                    </li>
                    <li>
                      <div>
                        Meta Data publishing through Lookup API or download via
                        FTP on request
                      </div>
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        )}

        {activeTab === "tab2" && (
          <div className="mt-4">
            <p>
              <Link>
                Activity/Sightseeing / Excursions / Day Tours CRS system
              </Link>{" "}
              allows multiple tour operators to load all their tours in a
              centralized database along with its rates, availability, schedules
              and policies along with capacity in each schedule. It could be
              connected with Enterprise Distribution platform via connector for
              further distribution
            </p>

            <div className="row">
              <div className="col-lg-8">
                <h4 className="mb-3">Features</h4>
                <ul>
                  <li>
                    Ability to have multilevel access - Administrator and Member
                    Users
                  </li>
                  <li>
                    Allows multiple tour operator inventory management using
                    single login access
                  </li>
                  <li>
                    Ability to provide consolidated output from all tour
                    operators as an API using enterprise distribution management
                    system
                  </li>
                  <li>
                    Ability to create unlimited tour/Activity/Excursion offers
                    under individual tour operator access
                  </li>
                  <li>
                    Ability to define inclusions, exclusions, general terms and
                    conditions for each tour separately
                  </li>
                  <li>
                    Policies setup (Payment policy, Cancellation policy, Tax
                    policy) at general tour operator as well as for individual
                    tour offers
                  </li>
                  <li>Multiple season as well as schedule based rate setup</li>
                  <li>
                    Traveler type wise rate setup (Adult, child, infant) / Per
                    unit based rate setup
                  </li>
                  <li>Ability to upload unlimited photos for each tour</li>
                  <li>Ability to upload tour brochure in PDF format</li>
                </ul>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        )}

        {activeTab === "tab3" && (
          <div className="mt-4">
            <p>
              <Link>Transfers CRS</Link> is a complete web-based solution
              developed for seamless online reservation, communication for
              online transfers booking business. It allows the Operators / owner
              to manage their business. They can create their point to point
              transfers individually and they can create rates and availability
              schedule for each Transfer.
            </p>
            <div className="row">
              <div className="col-lg-8">
                <h4 className="mb-3">Features</h4>
                <ul>
                  <li>
                    Ability to have multilevel access - Administrator and Member
                    Users
                  </li>
                  <li>
                    Allows multiple transfer operators inventory management
                    using single login access
                  </li>
                  <li>
                    Ability to provide consolidated output from all tour
                    operators as an API using enterprise distribution management
                    system
                  </li>
                  <li>
                    Ability to create unlimited transfer categories and routes
                    under individual operator access
                  </li>
                  <li>
                    Policies setup (Payment policy, Cancellation policy, Tax
                    policy) at general tour operator as well as for individual
                    tour offers
                  </li>
                  <li>Multiple season as well as schedule based rate setup</li>
                  <li>
                    Ability to do rate setup based on individual seat based as
                    well as for chartered services
                  </li>
                  <li>
                    Ability to upload unlimited photos for each transfer
                    category
                  </li>
                  <li>Ability to define terms and conditions</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <h2 className="text-center mt-5 mb-5">Client Success Stories</h2>

        <ul className="nav nav-tabs mt-5">
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTabN === "tab4" ? "active" : "")}
              onClick={() => setActiveN("tab4")}
            >
              A large technology company in Italy
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTabN === "tab5" ? "active" : "")}
              onClick={() => setActiveN("tab5")}
            >
              A large car rental association in New Zealand
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTabN === "tab6" ? "active" : "")}
              onClick={() => setActiveN("tab6")}
            >
              A large DMC in the Caribbean
            </button>
          </li>
        </ul>

        <div className="p-2 mt-3">
          {activeTabN === "tab4" && (
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <h5 className="fw-bold mb-3 text-primary">Client Overview</h5>
                  <ul className="list-tab-arr">
                    <li>
                      The client is a major technology company in Italy in
                      travel industry
                    </li>
                    <li>
                      They provide cloud based and fully{" "}
                      <strong>
                        customized solutions for the B2B and B2C tour operators
                      </strong>{" "}
                      and <strong>travel agencies</strong>
                    </li>
                    <li>
                      They have acquired significant skills and experience over
                      time not only in the area of software dedicated to tourism
                      but also as a Web Agency.
                    </li>
                  </ul>
                  <h5 className="fw-bold mb-3 mt-4 text-primary">
                    Client Requirements
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      They wanted to get a <strong>consolidated API</strong> for
                      different travel products (flights, hotels, activity, car
                      rentals, transfers) each with multiple online and
                      contracted suppliers.
                    </li>
                    <li>
                      They also wanted to build consolidated API's for services
                      like payment services and insurance services
                    </li>
                    <li>
                      They would build the front ends while we take care of the
                      engine
                    </li>
                    <li>
                      They approached us for our expertise in{" "}
                      <strong>API Management</strong> and our expertise in ESB
                      and API design
                    </li>
                    <li>
                      They needed a user interface through which it could manage
                      the subscription and feed distributions.
                    </li>
                  </ul>
                  <h5 className="fw-bold mb-3 mt-4 text-primary">
                    How TravelCarma Helped
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      <strong>TravelCarma</strong> helped the client{" "}
                      <strong>
                        integrate multiple suppliers and services API's
                      </strong>{" "}
                      into a single API
                    </li>
                    <li>
                      TravelCarma provides its engine and the technology and
                      they build front ends for their client
                    </li>
                    <li>
                      They only have to deal with single set of{" "}
                      <strong>XML schema</strong> to attach it to their{" "}
                      <strong>booking engine</strong>
                    </li>
                    <li>
                      TravelCarma also guided the client in understanding and
                      building a front end platform wherein they can host
                      multiple client systems on a cloud-based system
                    </li>
                    <li>
                      Additionally we provided them with{" "}
                      <strong>outbound API's</strong> to distribute the
                      aggregated feeds to multiple OTAs
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          )}

          {activeTabN === "tab5" && (
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <h5 className="fw-bold mb-3 text-primary">
                    Client Overview and Requirements
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      The client is one of the largest travel portals in New
                      Zealand with a million members. They had made a huge
                      investment in their portal development.
                    </li>
                    <li>
                      As it was constantly being debugged and patched they were
                      looking for vendors and technology which would allow them
                      to be able to integrate content modules directly into
                      their existing portals technology which was PHP.
                    </li>
                    <li>
                      They were looking for consulting and{" "}
                      <strong>technology solutions</strong> to enable them to
                      receive content, data and also{" "}
                      <a href="/travel-xml-api-integrations.htm">
                        supplier inventory
                      </a>{" "}
                      into their existing portal.
                    </li>
                  </ul>

                  <h5 className="fw-bold mb-3 mt-4 text-primary">
                    How TravelCarma Helped
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      We used DXchange as the Middleware and developed a custom
                      interface to their portals which allowed them to consume
                      external data as Web Services.
                    </li>
                    <li>
                      For deployment we sent an engineer from the
                      implementations group, to closely work with their IT team.
                      Here we integrated the content and also developed modules
                      for the same.
                    </li>
                    <li>
                      Our solutions enabled them to expand their portfolio of
                      offerings without disturbing their existing investment,
                      into their legacy portal and at the same time be able to
                      offer more web services, seamless integration and better
                      services to their customers.
                    </li>
                    <li>
                      Using our <strong>API Engine</strong>, they were able to
                      aggregate content and inventory from at least 15 different
                      suppliers, around 8 transactions per second and associated
                      searches.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          )}

          {activeTabN === "tab6" && (
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <h5 className="fw-bold mb-3 text-primary">Client Overview</h5>
                  <ul className="list-tab-arr">
                    <li>
                      The client is one of the most unique and progressive{" "}
                      <strong>hotel and tourism entities</strong> in the
                      Americas, having made significant contributions by
                      bringing together private sector ideas and funding in
                      support of their destination's tourism industry and
                      community at large.
                    </li>
                    <li>
                      While many affiliates of the client on an individual basis
                      had technology to capture direct business with{" "}
                      <strong>online booking engines</strong>, they achieved
                      limited success.
                    </li>
                    <li>
                      The majority of business received by partners was via{" "}
                      <strong>wholesale travel companies</strong> who are paid
                      high commissions
                    </li>
                  </ul>

                  <h5 className="fw-bold mb-3 mt-3 text-primary">
                    Client Requirements
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      The client wanted a destination portal with an online
                      booking system capable of effectively capturing
                      non-commissionable business on behalf of their members in
                      efforts to increase revenues.
                    </li>
                    <li>
                      Their site lacked the features of the latest technologies,
                      resulting in loss of possible performance Booking engine.
                    </li>
                    <li>
                      The <strong>booking engine</strong> on the site redirected
                      the user to another <strong>white-labeled site</strong>{" "}
                      which would easily confuse the users
                    </li>
                    <li>
                      A detailed SEO audit also revealed that there was a huge
                      scope of Search Engine Optimization for the web site and
                      also navigational ability of end users as well as search
                      engine robots
                    </li>
                  </ul>

                  <h5 className="fw-bold mb-3 mt-3 text-primary">
                    How TravelCarma Helped
                  </h5>
                  <ul className="list-tab-arr">
                    <li>
                      Extranets - The extranet would be a separate module and
                      was made available to the admin users of the client and
                      also the partner admin users. These member admin users
                      would be able to maintain their inventory of various
                      properties that they hold.
                    </li>
                    <li>
                      Booking Engines - The booking engines would be separate
                      modules for the given types of properties that the client
                      intended to sell via their official website. The products
                      the client wanted to promote were Hotels, Vehicles,
                      Restaurants and Excursions or Activities.
                    </li>
                    <li>
                      DXchange - Our dynamic middleware engine DXchange would be
                      used for the UI and the business logic to interact with
                      each other. This would make the system dynamic and totally
                      SOA based. It would also help in outbound distribution
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          )}
        </div>

        <h2 className="text-center mt-5 mb-5">E-IMDP Brochure</h2>
        <div className="text-center">
          <img src={EimdpBrochure} alt="EIMDP Brochure" />
          <br />
          <Link className="btn btn-primary mt-4" to={EimdpPdf} target="_blank">
            Click to Download Brochure
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SolutionsInventoryDistribution;
