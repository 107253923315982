import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CaseStudy1 from "../assets/images/customers/case-studies/FlyersConcierge.webp";
import CaseStudy2 from "../assets/images/customers/case-studies/Gotadi.webp";
import CaseStudy3 from "../assets/images/customers/case-studies/MyHotels.webp";
import CaseStudy4 from "../assets/images/customers/case-studies/CGM.webp";
import CaseStudy5 from "../assets/images/customers/case-studies/Debdab.webp";
import CaseStudy6 from "../assets/images/customers/case-studies/AllPeru.webp";
import CaseStudy7 from "../assets/images/customers/case-studies/AHTA.webp";
import CaseStudy8 from "../assets/images/customers/case-studies/Concierge.webp";
import CaseStudy9 from "../assets/images/customers/case-studies/AATravel.webp";
import PDFCaseStudy1 from "../assets/images/customers/case-studies/Case_Study_Flyers_Concierge.pdf";
import PDFCaseStudy2 from "../assets/images/customers/case-studies/Case_Study_Gotadi.pdf";
import PDFCaseStudy3 from "../assets/images/customers/case-studies/Case_Study_MyHotels.pdf";
import PDFCaseStudy4 from "../assets/images/customers/case-studies/Case_Study_CGM.pdf";
import PDFCaseStudy5 from "../assets/images/customers/case-studies/Case_Study_Debdab Travel.pdf";
import PDFCaseStudy6 from "../assets/images/customers/case-studies/Case_Study_All Peru Tours.pdf";
import PDFCaseStudy7 from "../assets/images/customers/case-studies/Case_Study_AHATA.pdf";
import PDFCaseStudy8 from "../assets/images/customers/case-studies/Case_Study_Travel Concierge.pdf";
import PDFCaseStudy9 from "../assets/images/customers/case-studies/Case_Study_AA Travel.pdf";

const CaseStudyBox = (props) => {
  const [cs, setCaseStudy] = useState();

  const data = [
    {
      id: "CS1",
      thumb: CaseStudy1,
      title: "TravelCarma Case Study - FLYERS CONCIERGE",
      download: PDFCaseStudy1,
    },
    {
      id: "CS2",
      thumb: CaseStudy2,
      title: "TravelCarma Case Study - OTA Solution",
      download: PDFCaseStudy2,
    },
    {
      id: "CS3",
      thumb: CaseStudy3,
      title: "TravelCarma Case Study - Hotel Booking Solution",
      download: PDFCaseStudy3,
    },
    {
      id: "CS4",
      thumb: CaseStudy4,
      title: "TravelCarma Case Study - Bus Booking Solution",
      download: PDFCaseStudy4,
    },
    {
      id: "CS5",
      thumb: CaseStudy5,
      title: "TravelCarma Case Study - B2B Agency Solution",
      download: PDFCaseStudy5,
    },
    {
      id: "CS6",
      thumb: CaseStudy6,
      title: "TravelCarma Case Study - Inbound Tour Operator Solution",
      download: PDFCaseStudy6,
    },
    {
      id: "CS7",
      thumb: CaseStudy7,
      title: "TravelCarma Case Study - Tourism Association Solution",
      download: PDFCaseStudy7,
    },
    {
      id: "CS8",
      thumb: CaseStudy8,
      title: "TravelCarma Case Study - Travel Concierge Solution",
      download: PDFCaseStudy8,
    },
    {
      id: "CS9",
      thumb: CaseStudy9,
      title: "TravelCarma Case Study - Car Booking Solution",
      download: PDFCaseStudy9,
    },
  ];

  const getData = () => {
    let box = data.find((x) => x.id === props?.id);
    setCaseStudy(box);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="text-center">
      <figure className="m-0 p-0">
        <img src={cs?.thumb} alt={cs?.title} className="rounded" />
      </figure>
      <Link className="btn btn-primary mt-4" to={cs?.download} target="_blank">
        Download
      </Link>
    </div>
  );
};

export default CaseStudyBox;
