import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import TDXIntro from "../assets/images/solutions/tdx.webp";
import TDXDiagram from "../assets/images/solutions/tdx-diagram.webp";

import DiverseContent from "../assets/images/slider/data-exchange/diverse-content.webp";
import BuildOwnAdapters from "../assets/images/slider/data-exchange/build-own-adapters.webp";
import ReducedCost from "../assets/images/slider/data-exchange/reduced-cost.webp";
import ShorterTime from "../assets/images/slider/data-exchange/shorter-time-to-market.webp";
import EaseOfDistribution from "../assets/images/slider/data-exchange/ease-of-distribution.webp";
import Flexibility from "../assets/images/slider/data-exchange/flexibility.webp";
import TrafficVolumes from "../assets/images/slider/data-exchange/traffic-volumes.webp";
import MappedHotelInventory from "../assets/images/slider/data-exchange/mapped-hotel-inventory.webp";
import FeedManagement from "../assets/images/slider/data-exchange/feed-management.webp";

import LowCostAirlines from "../assets/resources/Low-Cost-Airlines-Integrated-by-TravelCarma.pdf";

const SolutionsTravelDataXchange = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="travel-data-xchange" />
      <div className="container">
        <div className="p-5 shadow rounded">
          <h2
            className="text-center mb-5 text-primary"
            style={{ fontSize: "28px" }}
          >
            Integrating multiple Supplier APIs can be a nightmare
          </h2>
          <p className="text-center">
            If you are an OTA, a consolidator, a TMC or a DMC, you may be
            looking to integrate multiple suppliers in order to provide your
            clients with diverse content and best possible rates.
          </p>
          <p className="text-center mb-0">
            However, with so many suppliers out there, each with their own
            schema and interface, integrating each one of them individually can
            cost a huge amount of time, effort and money.
          </p>
        </div>

        <div className="p-5  mt-5 shadow rounded">
          <h2
            className="text-center text-primary mb-5"
            style={{ fontSize: "28px" }}
          >
            We have a solution that makes it a piece of cake!
          </h2>

          <div className="row">
            <div className="col-lg-6 text-center">
              <img
                src={TDXIntro}
                alt="Travel API Aggregation Platform"
                style={{ width: "324px" }}
              />
            </div>
            <div className="col-lg-6 text-justify">
              <p style={{ fontSize: "1.17rem", lineHeight: "34px" }}>
                TravelCarma TDX is a cloud-based integration and aggregation
                platform that allows travel organizations to integrate multiple
                supplier APIs and consume the same through a unified API.
              </p>
              <p style={{ fontSize: "1.17rem", lineHeight: "34px" }}>
                With TDX, you can easily aggregate and distribute live content
                from 'n' number of suppliers using a single API, allowing you to
                save a phenomenal amount of time and money.
              </p>
            </div>
          </div>
        </div>

        <h2 className="text-center mb-5 mt-5" style={{ fontSize: "28px" }}>
          TDX integrates with all types of third-party systems, including
        </h2>

        <ul className="list-unstyled row tdx-boxes">
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>GDSs (Global Distribution
              Systems)
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>LCCs
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Consolidators
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Bedbanks
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Channel Managers
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Car Rental and Transfers
              Providers
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Bus Operators
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Sightseeing and Excursions
              Providers
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>DMCs
            </h5>
          </li>
          <li className="col-lg-6">
            <h5>
              <i className="material-icons"></i>Payment Gateways
            </h5>
          </li>
        </ul>

        <div className="text-center shadow rounded p-4 mb-5">
          <img
            src={TDXDiagram}
            className="w-100"
            alt="Travel Unified API Platform"
            style={{ maxWidth: "1500px" }}
          />
        </div>
      </div>

      <div className="mt-4 pt-5 pb-5" style={{ background: "#380085" }}>
        <div className="container">
          <p className="text-white text-center m-0">
            We have ready connections for over{" "}
            <Link
              to="/suppliers/travel-xml-api-integrations"
              className="text-primary"
            >
              80 suppliers
            </Link>{" "}
            and{" "}
            <Link to={LowCostAirlines} target="_blank" className="text-primary">
              130+ LCCs
            </Link>{" "}
            from across the globe. We keep adding new connectors, and if the
            suppliers you wish to add are not on the list, just let us know. We
            will be happy to integrate them for you or your team can even build
            the integration themselves using our SDK. All you need is a
            commercial API agreement with the suppliers you wish to integrate in
            order to activate live feeds.
          </p>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mb-5 mt-5" style={{ fontSize: "28px" }}>
          Benefits of TDX
        </h2>

        <div className="row solutions-section text-center">
          <div className="col-lg-8">
            <h2>Supports Diverse Content</h2>
            <p>
              With TDX, you can integrate multiple products - flights, hotels,
              sightseeing, tours, car rentals, transfers, rail, bus, cruise and
              many more! With a single search request to TDX, you will get
              aggregated feeds from all the suppliers you've activated, on a
              real-time basis. What's more, we even have a payment gateway API
              with which you can add/change your payments provider without
              changing your application integration code
            </p>
          </div>
          <div className="col-lg-4">
            <div>
              <img
                src={DiverseContent}
                alt="Travel Unified API Platform - Diverse Content"
                className="w-100"
              />
            </div>
          </div>
          <div className="solutions-separator"></div>

          {/* <div className="col-lg-4">
            <img
              src={BuildOwnAdapters}
              alt="Travel Unified API Platform - Adapters"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <h2>Supports Diverse Content</h2>
            <p>
              With TDX, you can integrate multiple products - flights, hotels,
              sightseeing, tours, car rentals, transfers, rail, bus, cruise and
              many more! With a single search request to TDX, you will get
              aggregated feeds from all the suppliers you've activated, on a
              real-time basis. What's more, we even have a payment gateway API
              with which you can add/change your payments provider without
              changing your application integration code
            </p>
          </div>
          <div className="solutions-separator"></div> */}

          <div className="col-lg-4">
            <img
              src={BuildOwnAdapters}
              alt="Build your own Adapters"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <h2>Build your own Adapters</h2>
            <p>
              TDX is the first of its kind solution that allows you the customer
              to build your own connections to your preferred suppliers using
              our SDK. Thus, you and your own technical team can maintain and
              change suppliers on your own
            </p>
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-8">
            <h2>Reduced Development Cost</h2>
            <p>
              The cost of developing and maintaining several connections can be
              huge. By providing you with a unified API, TDX drastically cuts
              down that cost for you. It also eliminates the costs you’ll incur
              in the event of any schema changes in supplier API
            </p>
          </div>
          <div className="col-lg-4">
            <img
              src={ReducedCost}
              alt="Reduced Development Cost"
              className="w-100"
            />
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-4">
            <img
              src={ShorterTime}
              alt="Shorter Time to Market"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <h2>Shorter Time to Market</h2>
            <p>
              With TDX you won't have to spend months integrating multiple
              suppliers in your application. You will need to implement just a
              single integration to go live with all your supplier feeds in
              days, rather than months. It will also make it quicker to add new
              connections in the future
            </p>
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-8">
            <h2>Ease of Distribution</h2>
            <p>
              TDX comes with a channel distribution option. Along with a pricing
              rules engine and a revenue management solution you can finely
              control your pricing to each individual sales channel. And a
              unified API makes it far easier for you to integrate and
              distribute your aggregated inventory to resellers, as they also
              need to deal with just one integration. The aggregated output can
              be in any format and can be consumed by both web and mobile
              applications. This will open up multiple sales channels for you,
              manage your costs and sales pricing and transform into a global
              supplier relatively quickly
            </p>
          </div>
          <div className="col-lg-4">
            <img
              src={EaseOfDistribution}
              alt="Ease of Distribution"
              className="w-100"
            />
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-4">
            <img
              src={Flexibility}
              alt="Flexibility through Data Transformation"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <h2>Flexibility through Data Transformation</h2>
            <p>
              In case you have an existing booking solution in place and plan to
              replace your aggregation and distribution platform it can be done
              easily with any disruptions to your application. TDX has the
              ability to transform and present data in any schema. This means we
              can provide you with an output schema that mimics the structure
              and schema used by your booking engine. So, you can integrate any
              suppliers you want, regardless of their schema/version, without
              making changes to your booking platform code
            </p>
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-8">
            <h2>Can Handle Large Traffic Volumes</h2>
            <p>
              TDX has been designed for the cloud and uses the latest cloud
              serverless technologies thus providing you scalability on the go.
              It scales as your traffic increases and scales down in off peak
              hours thus saving costs. It's currently hosted on AWS (Amazon Web
              Services) which provides scalability and high performance even
              during peak periods. Bandwidth can be easily adjusted depending on
              the expected traffic volume and in-depth traffic analytics are
              available for monitoring. The solution can also be hosted on any
              other cloud or private cloud if required
            </p>
          </div>
          <div className="col-lg-4">
            <img
              src={TrafficVolumes}
              alt="Can Handle Large Traffic Volumes"
              className="w-100"
            />
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-4">
            <img
              src={MappedHotelInventory}
              alt="Mapped Hotel Inventory"
              className="w-100"
            />
          </div>
          <div className="col-lg-8">
            <h2>Mapped Hotel Inventory</h2>
            <p>
              Integrating multiple hotel suppliers translates into inventory
              duplication, which hampers the user experience. TDX has hotel
              mapping built-in which ensures that you get unique and accurate
              information for available properties
            </p>
          </div>
          <div className="solutions-separator"></div>

          <div className="col-lg-8">
            <h2>Superior Feed and Revenue Management</h2>
            <p>
              With TDX you can easily turn on/turn off feeds whenever you want.
              You can switch off the feeds where your supplier deposit balance
              is low, order to optimize the use of your deposit and make more
              money. You can also control airlines in the feeds and even switch
              to different suppliers automatically for different destinations or
              nationality, minimizing your effort and optimizing your sales
            </p>
          </div>
          <div className="col-lg-4">
            <img
              src={FeedManagement}
              alt="Superior Feed and Revenue Management"
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsTravelDataXchange;
