import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/TravelCarma-Logo.webp";
import Menu from "./menu";

const Header = () => {
  return (
    <header className="pt-3 pb-3" style={{ boxShadow: "0 2px 8px #f0f1f2" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/" className="text-secondary">
              <img src={Logo} alt="TravelCarma" height="72px" />
            </Link>
          </div>
          <div className="col-lg-9 d-flex justify-content-end">
            <Menu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
