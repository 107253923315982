import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import CustomerMap from "../assets/images/customers/customer-map.webp";
import CustomerLogo1 from "../assets/images/customers/customer-logo-1.webp";
import CustomerLogo2 from "../assets/images/customers/customer-logo-2.webp";
import CustomerLogo3 from "../assets/images/customers/customer-logo-3.webp";
import CustomerLogo4 from "../assets/images/customers/customer-logo-4.webp";
import CustomerLogo5 from "../assets/images/customers/customer-logo-5.webp";
import CustomerLogo6 from "../assets/images/customers/customer-logo-6.webp";
import CustomerLogo7 from "../assets/images/customers/customer-logo-7.webp";
import CustomerLogo8 from "../assets/images/customers/customer-logo-8.webp";
import CustomerLogo9 from "../assets/images/customers/customer-logo-9.webp";
import CustomerLogo10 from "../assets/images/customers/customer-logo-10.webp";
import CustomerLogo11 from "../assets/images/customers/customer-logo-11.webp";
import CustomerLogo12 from "../assets/images/customers/customer-logo-12.webp";
import CustomerLogo13 from "../assets/images/customers/customer-logo-13.webp";
import CustomerLogo14 from "../assets/images/customers/customer-logo-14.webp";
import CustomerLogo15 from "../assets/images/customers/customer-logo-15.webp";
import CustomerLogo16 from "../assets/images/customers/customer-logo-16.webp";
import CustomerLogo17 from "../assets/images/customers/customer-logo-17.webp";
import CustomerLogo18 from "../assets/images/customers/customer-logo-18.webp";
import CustomerLogo19 from "../assets/images/customers/customer-logo-19.webp";
import CustomerLogo20 from "../assets/images/customers/customer-logo-20.webp";
import CustomerLogo21 from "../assets/images/customers/customer-logo-21.webp";
import CustomerLogo22 from "../assets/images/customers/customer-logo-22.webp";
import CustomerLogo23 from "../assets/images/customers/customer-logo-23.webp";
import CustomerLogo24 from "../assets/images/customers/customer-logo-24.webp";
import CustomerLogo25 from "../assets/images/customers/customer-logo-25.webp";
import CustomerLogo26 from "../assets/images/customers/customer-logo-26.webp";
import CustomerLogo27 from "../assets/images/customers/customer-logo-27.webp";
import CustomerLogo28 from "../assets/images/customers/customer-logo-28.webp";
import CustomerLogo29 from "../assets/images/customers/customer-logo-29.webp";
import CustomerLogo30 from "../assets/images/customers/customer-logo-30.webp";
import CustomerLogo31 from "../assets/images/customers/customer-logo-31.webp";
import CustomerLogo32 from "../assets/images/customers/customer-logo-32.webp";

const Customers = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="customers" />
      <div className="container">
        <div className="text-center">
          <div>
            <img
              className="w-100 mb-4"
              src={CustomerMap}
              alt="TravelCarma has customers in over 50 countries"
              style={{ maxWidth: "1111px" }}
            />
          </div>

          <h2 className="text-center mb-4">Here are some of our clients</h2>

          <ul className="customers-logos suppliers-logos mt-5">
            <li>
              <img
                src={CustomerLogo1}
                alt="TravelCarma Client - Abound World Travel"
              />
            </li>
            <li>
              <img src={CustomerLogo2} alt="TravelCarma Client - Four Square" />
            </li>
            <li>
              <img
                src={CustomerLogo3}
                alt="TravelCarma Client - Albania Explorer"
              />
            </li>
            <li>
              <img
                src={CustomerLogo4}
                alt="TravelCarma Client - AlFanar Travel"
              />
            </li>
            <li>
              <img src={CustomerLogo5} alt="TravelCarma Client - Break Saves" />
            </li>
            <li>
              <img
                src={CustomerLogo6}
                alt="TravelCarma Client - Debdab Travel"
              />
            </li>
            <li>
              <img
                src={CustomerLogo7}
                alt="TravelCarma Client - Halal Trip Info"
              />
            </li>
            <li>
              <img src={CustomerLogo8} alt="TravelCarma Client - HG Travel" />
            </li>
            <li>
              <img src={CustomerLogo9} alt="TravelCarma Client - Key Booking" />
            </li>
            <li>
              <img
                src={CustomerLogo10}
                alt="TravelCarma Client - Iconic Travel"
              />
            </li>
            <li>
              <img src={CustomerLogo11} alt="TravelCarma Client - Indiblue" />
            </li>
            <li>
              <img
                src={CustomerLogo12}
                alt="TravelCarma Client - Jet Set Tourism"
              />
            </li>
            <li>
              <img src={CustomerLogo13} alt="TravelCarma Client - Travellala" />
            </li>
            <li>
              <img
                src={CustomerLogo14}
                alt="TravelCarma Client - World Online Booking"
              />
            </li>
            <li>
              <img
                src={CustomerLogo15}
                alt="TravelCarma Client - Travel Pilgrim"
              />
            </li>
            <li>
              <img src={CustomerLogo16} alt="TravelCarma Client - TravelShop" />
            </li>
            <li>
              <img
                src={CustomerLogo17}
                alt="TravelCarma Client - TravelShop-BD"
              />
            </li>
            <li>
              <img src={CustomerLogo18} alt="TravelCarma Client - Traviva" />
            </li>
            <li>
              <img
                src={CustomerLogo19}
                alt="TravelCarma Client - Ugo Holidays"
              />
            </li>
            <li>
              <img src={CustomerLogo20} alt="TravelCarma Client - Webcola" />
            </li>
            <li>
              <img src={CustomerLogo21} alt="TravelCarma Client - Zoom4Word" />
            </li>
            <li>
              <img
                src={CustomerLogo22}
                alt="TravelCarma Client - Karamba Tours"
              />
            </li>
            <li>
              <img
                src={CustomerLogo23}
                alt="TravelCarma Client - Mykonos Accommodation Center"
              />
            </li>
            <li>
              <img
                src={CustomerLogo24}
                alt="TravelCarma Client - Berry Travel Service"
              />
            </li>
            <li>
              <img src={CustomerLogo25} alt="TravelCarma Client - BAYS.com" />
            </li>
            <li>
              <img
                src={CustomerLogo26}
                alt="TravelCarma Client - Book Zanzibar Hotels"
              />
            </li>
            <li>
              <img src={CustomerLogo27} alt="TravelCarma Client - B2B Rooms" />
            </li>
            <li>
              <img
                src={CustomerLogo28}
                alt="TravelCarma Client - Perseus - Mexico"
              />
            </li>
            <li>
              <img src={CustomerLogo29} alt="TravelCarma Client - exrooms" />
            </li>
            <li>
              <img
                src={CustomerLogo30}
                alt="TravelCarma Client - Univida Travel"
              />
            </li>
            <li>
              <img
                src={CustomerLogo31}
                alt="TravelCarma Client - Dolphin Destiny Adventures"
              />
            </li>
            <li>
              <img
                src={CustomerLogo32}
                alt="TravelCarma Client - Travel Solution Group"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Customers;
