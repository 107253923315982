import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import Img1 from "../assets/images/company/partner-program-how-it-works.webp";

const CompanyCareers = () => {
  return (
    <div className="page-inside">
      <PageHeader page="careers" />
      <div className="container">
        <div class="careers-benefits-wrap">
          <p>
            TravelCarma is a leading travel tech provider based out of
            Ahmedabad, India. With over 20 years of software product development
            experience, we have helped tourism companies worldwide achieve a
            significant online presence through our state-of-the-art solutions.
          </p>
          <p>
            We, at TravelCarma, promote creativity and dynamism. We encourage an
            environment that balances work and play! If you are looking for a
            blend of ambitious goals and a work-life balance, TravelCarma is the
            place for you.
          </p>
          <p>
            We foster growth and recognize individuality. TravelCarma is not
            just a company; it’s a family that embraces the most humble and
            zealous workforce.
          </p>
          <ul class="careers-benefits-box">
            <li>
              <i class="material-icons"></i>
              <h3>Great Commissions</h3>
              <span>
                Earn a Commission on every sale you make &amp; Bonus Payments
                for Sales of Strategically important Services
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Value Added Services</h3>
              <span>
                Earn additional revenue by selling your own value added services
                &amp; local support with our solutions. This can be huge in case
                of enterprise deals
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Knowledge &amp; Training</h3>
              <span>
                In-depth industry and system training to equip you with all the
                knowledge necessary to maximize sales
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Ready to Sell Solutions</h3>
              <span>
                No need to build solutions from scratch. Just sell our existing
                solutions to your clients and minimize time-to-market
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Dedicated Support</h3>
              <span>
                Pre-sales and post-sales technical support to help you overcome
                any difficulties you might face servicing clients
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Tools and Resources</h3>
              <span>
                We will provide all the necessary Sales and Marketing material
                to help you close more deals
              </span>
            </li>
          </ul>
          <ul class="careers-benefits-list">
            <li>
              <i class="material-icons"></i>Have expertise in travel website
              development? Great! You can focus on building awesome looking
              websites for your clients and we can provide the booking engine
              and back-office.
            </li>
            <li>
              <i class="material-icons"></i>Have a great CRM/Marketing
              Automation product that you’re proud of? Awesome! You can connect
              your product with our online booking system and offer your
              customers the ability to seamlessly manage their bookings and run
              their marketing campaigns from a single platform!
            </li>
            <li>
              <i class="material-icons"></i>Provide business consulting to
              tourism companies? Well, now you can provide them world-class
              technology solutions too!
            </li>
            <li>
              <i class="material-icons"></i>Our flexible system architecture
              will allow your clients to easily integrate their existing
              technology with our platform to protect their investments and
              data.
            </li>
            <li>
              <i class="material-icons"></i>Moreover, you can own the support
              process and provide post-sales training and service to your
              clients at your own prices and make more money
            </li>
          </ul>
        </div>
        <div class="careers-success">
          <h2>Benefits We Provide</h2>
          <div class="careers-success-wrap alignCenter">
            <ul class="careers-success-list alignCenter">
              <li>
                <i class="material-icons"></i>Active Learning &amp; Development
              </li>
              <li>
                <i class="material-icons"></i>Continuous
                <br />
                R&amp;D
              </li>
              <li>
                <i class="material-icons"></i>Regular Leisure <br />
                Activities
              </li>
              <li>
                <i class="material-icons"></i>Flat Organizational <br />
                Structure
              </li>
              <li>
                <i class="material-icons"></i>Informal Working Environment
              </li>
              <li>
                <i class="material-icons"></i>Greater <br />
                Exposure
              </li>
            </ul>
          </div>
          <h2>ROI our careerss have experienced</h2>
          <div class="careers-roi-wrap">
            <ul class="careers-roi-list">
              <li>
                <i class="material-icons"></i>
                <b>5x</b> Sales Conversions
              </li>
              <li>
                <i class="material-icons"></i>
                <b>3x</b> Revenue Growth
              </li>
              <li>
                <i class="material-icons"></i>
                <b>2x</b> Client Retention
              </li>
            </ul>
          </div>
          <div class="careers-roi-overlay"></div>
        </div>
        <h2>Life at TravelCarma</h2>
        <h2>Current Openings</h2>
      </div>
    </div>
  );
};

export default CompanyCareers;
