import React from "react";
import PageHeader from "../components/page-header";
import B2bBookingSystem from "../assets/images/products/b2b-booking-system-clipart.webp";
import ImageSlider from "../components/image-slider";
import { Link } from "react-router-dom";

const ProductsB2BBookingSystem = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="b2b-booking-system" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={B2bBookingSystem}
              alt="TravelCarma B2B Booking System"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Our B2B booking solution allows your sub-agents and corporate
              clients to search and book your inventory online and makes it
              extremely easy for you to manage all your B2B customers from a
              single interface.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              It integrates with third-party suppliers via API, as well as your
              contracted inventory to help you offer your B2B clients a wide
              range of products at competitive rates.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              What's unique about our B2B reservation system is the ability to
              create multiple levels of downline agents with class-wise markups,
              and the flexibility to provide agents at each level fully-fledged
              co-branded B2C portals with back-office where they can add their
              own markups on top of your rates to further increase your sales.
            </p>

            <Link to="/contact-us" className="btn btn-primary mb-5">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5">Top Benefits for your Business</h2>

        <div className="row text-center benefits-section">
          <div className="col-lg-6">
            <h2>Automate & Manage your B2B bookings</h2>
            <ul className="list-sm-arr">
              <li>Single booking interface for all your downline B2B agents</li>
              <li>
                Fully-fledged co-branded booking portals for the end users of
                your agents(B2B2C)
              </li>
              <li>
                Advanced search and filter options, help your customers make
                informed choices
              </li>
              <li>
                Agents and employees can create quotations with live as well as
                offline fares and make the bookings on behalf of the customers
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BBS1" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BBS2" />
          </div>
          <div className="col-lg-6">
            <h2>Real Time Availability and Rates</h2>
            <ul className="list-sm-arr">
              <li>Integrate Multiple GDSs and Third Party XML Suppliers</li>
              <li>Display accurate inventory availability in real-time</li>
              <li>
                Ability to configure the inventory and information accessible to
                agents
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Manage all your B2B Agents in one place</h2>
            <ul className="list-sm-arr">
              <li>Set up sub-agents and their details</li>
              <li>Define agent credit limits</li>
              <li>Set up mark-ups and commissions</li>
              <li>Agent-wise online balance recharge facility</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BBS3" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BBS4" />
          </div>
          <div className="col-lg-6">
            <h2>Streamline Back Office & Reporting</h2>
            <ul className="list-sm-arr">
              <li>
                Customizable Email templates for various types of B2B
                communications
              </li>
              <li>
                Agents can create agents under them, manage their markups and
                provide them with their own B2C portals
              </li>
              <li>
                Detailed MIS reports to help you track the performance of your
                agents
              </li>
              <li>
                Facility for downline agents to apply real-time markups on their
                B2C bookings
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsB2BBookingSystem;
