import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import Img1 from "../assets/images/company/partner-program-how-it-works.webp";

const CompanyPartnerProgram = () => {
  return (
    <div className="page-inside">
      <PageHeader page="partner-program" />
      <div className="container">
        <p className="text-center">
          There is Phenomenal Growth in Online Travel Today. Online Travel Sales
          are expected to cross $800 billion by 2020 and will account for 60% of
          Bookings. It is predicted that the Travel Technology Sector will be a
          $12 billion/year industry by 2019. Looking at this trend, Tourism
          companies worldwide are looking to invest in Travel Technology to
          capture this growth and stay ahead of the curve in this ever changing
          industry.
        </p>
        <p className="text-center fw-bold text-primary">
          We are seeking strategic partners to profitably distribute our
          award-winning travel tech solutions in different geographies. With the
          right partners, we are confident that together we will be able to
          exploit this enormous opportunity!
        </p>
        <p className="text-center">
          We offer flexible partnership opportunities as per your interest,
          reach, influence in your local markets. It is an extremely rewarding
          program which promises great returns to every partner associated with
          TravelCarma.
        </p>

        <h2 className="text-center">Who is it for?</h2>
        <ul>
          <li>
            <i class="material-icons"></i>Established IT Companies looking to
            expand their technology offerings and service new inquiries as well
            as existing clients in the tourism vertical
          </li>
          <li>
            <i class="material-icons"></i>Digital Marketing Agencies who want to
            offer bundled travel e-commerce solutions along with their own
            website/mobile app development and integrated marketing services
          </li>
          <li>
            <i class="material-icons"></i>Technology Consultants acting as
            intermediaries between travel companies and tech vendors to help
            their clients access the right technology solutions for their needs
            and budget
          </li>
        </ul>

        <h2 className="text-center">How it works</h2>
        <div className="text-center">
          <img src={Img1} alt="How it works" />
        </div>

        <h2 className="text-center">Partner Benefits</h2>
        <div class="partner-benefits-wrap">
          <p>
            By partnering with us, established IT companies and digital
            marketing agencies can leverage our 20+ years of experience in the
            travel technology domain, and offer their clients a world-class
            travel e-commerce solution in addition to their own products and
            services.
          </p>
          <p>
            You can combine your local experience, credentials and services with
            our enterprise software to offer your customers an integrated
            solution for all their needs under one roof!
          </p>
          <p>
            This is will not only help you keep your existing clients happier
            but also help you win new clients in what is one of the fastest
            growing industries. We will provide you a handsome share in the
            revenue generated from each sale you make, plus a number of other
            benefits highlighted below.
          </p>
          <ul class="partner-benefits-box">
            <li>
              <i class="material-icons"></i>
              <h3>Great Commissions</h3>
              <span>
                Earn a Commission on every sale you make &amp; Bonus Payments
                for Sales of Strategically important Services
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Value Added Services</h3>
              <span>
                Earn additional revenue by selling your own value added services
                &amp; local support with our solutions. This can be huge in case
                of enterprise deals
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Knowledge &amp; Training</h3>
              <span>
                In-depth industry and system training to equip you with all the
                knowledge necessary to maximize sales
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Ready to Sell Solutions</h3>
              <span>
                No need to build solutions from scratch. Just sell our existing
                solutions to your clients and minimize time-to-market
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Dedicated Support</h3>
              <span>
                Pre-sales and post-sales technical support to help you overcome
                any difficulties you might face servicing clients
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Tools and Resources</h3>
              <span>
                We will provide all the necessary Sales and Marketing material
                to help you close more deals
              </span>
            </li>
          </ul>
          <ul class="partner-benefits-list">
            <li>
              <i class="material-icons"></i>Have expertise in travel website
              development? Great! You can focus on building awesome looking
              websites for your clients and we can provide the booking engine
              and back-office.
            </li>
            <li>
              <i class="material-icons"></i>Have a great CRM/Marketing
              Automation product that you’re proud of? Awesome! You can connect
              your product with our online booking system and offer your
              customers the ability to seamlessly manage their bookings and run
              their marketing campaigns from a single platform!
            </li>
            <li>
              <i class="material-icons"></i>Provide business consulting to
              tourism companies? Well, now you can provide them world-class
              technology solutions too!
            </li>
            <li>
              <i class="material-icons"></i>Our flexible system architecture
              will allow your clients to easily integrate their existing
              technology with our platform to protect their investments and
              data.
            </li>
            <li>
              <i class="material-icons"></i>Moreover, you can own the support
              process and provide post-sales training and service to your
              clients at your own prices and make more money
            </li>
          </ul>
        </div>

        <h2>ROI our partners have experienced</h2>
        <ul class="partner-roi-list">
          <li>
            <i class="material-icons"></i>
            <b>5x</b> Sales Conversions
          </li>
          <li>
            <i class="material-icons"></i>
            <b>3x</b> Revenue Growth
          </li>
          <li>
            <i class="material-icons"></i>
            <b>2x</b> Client Retention
          </li>
        </ul>

        <h2>What we expect from our partners</h2>
        <div class="partner-expect-wrap">
          <p>
            Our Partners serve as TravelCarma’s representatives in specific
            geographic areas. Their employees engage customers and close sales,
            assuming responsibility for all client care, contracting, pricing
            and billing.
          </p>
          <ul>
            <li>
              <i class="material-icons"></i>
              <h3>Lead Generation &amp; Brand Awareness</h3>
              <span>
                Promote the TravelCarma brand in specific regions and generate
                quality leads through your own marketing
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Convert Leads into Customers</h3>
              <span>
                Own the sales process - schedule calls/meetings, show product
                demos, send proposals &amp; contracts, process billing etc
              </span>
            </li>
            <li>
              <i class="material-icons"></i>
              <h3>Provide Local Support</h3>
              <span>
                Handhold clients through training, implementation and after
                sales services &amp; support, including providing of your own
                value added services
              </span>
            </li>
          </ul>
          <h2>What we look for in our partners</h2>
          <div class="partner-look-wrap">
            <ul>
              <li>
                <i class="material-icons"></i>An established business setup with
                a strong client base(sorry, we don’t work with startups)
              </li>
              <li>
                <i class="material-icons"></i>Knowledge of how the travel
                industry works
              </li>
              <li>
                <i class="material-icons"></i>Prior experience in working with
                clients from the travel industry
              </li>
              <li>
                <i class="material-icons"></i>A passion for travel technology
              </li>
              <li>
                <i class="material-icons"></i>The ability to understand customer
                needs and how to profitably service them
              </li>
              <li>
                <i class="material-icons"></i>Willingness to work hard and
                constantly follow up with leads
              </li>
              <li>
                <i class="material-icons"></i>Strong sales and marketing skills
                with the ability to independently generate leads &amp; close
                deals
              </li>
            </ul>
            <b>
              If you're interested in joining our partner program, please write
              to us on{" "}
              <Link to="mailto:partners@travelcarma.com">
                partners@travelcarma.com
              </Link>{" "}
              or fill out the form below.
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyPartnerProgram;
