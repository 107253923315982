import React, { useEffect, useState } from "react";
import SliderImg1 from "../assets/images/slider/home/slider-img-1.webp";
import SliderImg2 from "../assets/images/slider/home/slider-img-2.webp";
import SliderImg3 from "../assets/images/slider/home/slider-img-3.webp";
import SliderImg4 from "../assets/images/slider/home/slider-img-4.webp";
import SliderImg5 from "../assets/images/slider/home/slider-img-5.webp";
import { Link } from "react-router-dom";

const HomeSlider = () => {
  const [current, setCurrent] = useState(1);

  const handleSlide = (action) => {
    if (action === "next") {
      current < 5 ? setCurrent(current + 1) : setCurrent(1);
    }
    if (action === "prev") {
      current > 1 ? setCurrent(current - 1) : setCurrent(5);
    }
  };
  return (
    <div className="home-slider">
      {current === 1 && (
        <div className="home-slide home-slide1">
          <img className="w-100" src={SliderImg1} alt="Home-Slider" />
          <div className="home-slider-content">
            <div className="container">
              <h2>Enterprise Travel Tech Solutions</h2>
              <ul>
                <li>
                  <i className="material-icons"></i>
                  We help large travel companies leverage their existing IT
                  infrastructure and accelerate growth with highly scalable &
                  flexible microservices-based solutions.
                </li>
                <li>
                  <i className="material-icons"></i>
                  With our modular architecture and customizable travel
                  components, you can build your own solutions the way you want.
                  You can access our components as services and easily plug them
                  into your existing infrastructure to save on costs and time to
                  deployment.
                </li>
              </ul>
              <span className="home-slider-mobile" style={{ display: "none" }}>
                We help large travel companies leverage their existing IT
                infrastructure and accelerate growth with highly scalable &
                flexible microservices-based solutions.
              </span>
              <Link
                to="/solutions/travel-agency-software"
                className="btn btn-sm btn-primary"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}

      {current === 2 && (
        <div className="home-slide home-slide2">
          <img className="w-100" src={SliderImg2} alt="Home-Slider" />
          <div className="home-slider-content">
            <div className="container">
              <h2>Travel Data Xchange (TDX)</h2>
              <p>
                Powerful API aggregation hub specifically designed for online
                travel that allows you to aggregate multiple suppliers (of
                flights, hotels, car rentals, excursions, packages, transfers,
                insurance and add-ons) and consume the same through an unified
                API
              </p>
              <ul>
                <li>
                  <i className="material-icons"></i>
                  Easily fits within your existing infrastructure
                </li>
                {/* <li>
                  <i className="material-icons"></i>
                  Comes with an SDK and templates that provide the flexibility
                  to easily build your own connections to any suppliers of
                  travel content. Easily add new connections in the future -
                  Build any connection to any travel supplier in 10 days with
                  your existing team
                </li> */}
                <li>
                  <i className="material-icons"></i>
                  Comes with with its workflow engine and hook for applying
                  business rules to the data
                </li>
                <li>
                  <i className="material-icons"></i>
                  Unified API makes it easy to integrate into your front end,
                  reduces development costs drastically and comes with sample
                  code and cases.
                </li>
                <li>
                  <i className="material-icons"></i>
                  Easily hosted on AWS or private cloud
                </li>
              </ul>
              <span className="home-slider-mobile" style={{ display: "none" }}>
                Powerful API aggregation hub specifically designed for online
                travel that allows you to aggregate multiple suppliers (of
                flights, hotels, car rentals, excursions, packages, transfers,
                insurance and add-ons) and consume the same through an unified
                API
              </span>
              <Link
                to="/solutions/travel-data-xchange-switch"
                className="btn btn-sm btn-primary"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}

      {current === 3 && (
        <div className="home-slide home-slide1">
          <img className="w-100" src={SliderImg3} alt="Home-Slider" />
          <div className="home-slider-content">
            <div className="container">
              <h2>Travel Portal development Engine</h2>
              <p>
                World first modular Travel Engine accessible through API's with
                modules for Agency management, revenue management, IBE, User
                management, CRM, configurable workflows, front end templates,
                Mobile Front ends etc.
              </p>
              <ul>
                <li>
                  <i className="material-icons"></i>
                  Plug-and-play components for various functionalities
                </li>
                <li>
                  <i className="material-icons"></i>
                  Flexibility to build custom UI/UX over each component
                </li>
                <li>
                  <i className="material-icons"></i>
                  Platform agnostic - Compatible with all platforms & devices
                </li>
                <li>
                  <i className="material-icons"></i>
                  Significant reduction in development time & cost
                </li>
                <li>
                  <i className="material-icons"></i>
                  Faster time-to-market as only front ends need to be built
                </li>
                <li>
                  <i className="material-icons"></i>
                  Allows use of existing travel components thus making most of
                  your existing investments
                </li>
              </ul>
              <span className="home-slider-mobile" style={{ display: "none" }}>
                World first modular Travel Engine accessible through API’s with
                modules for Agency management, revenue management, IBE, User
                management, CRM, configurable workflows, front end templates,
                Mobile Front ends etc.
              </span>
              <Link
                to="/solutions/development-api"
                className="btn btn-sm btn-primary"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}

      {current === 4 && (
        <div className="home-slide home-slide1">
          <img className="w-100" src={SliderImg4} alt="Home-Slider" />
          <div className="home-slider-content">
            <div className="container">
              <h2>Travel Distribution Platform (E-IMDP)</h2>
              <p>
                Customizable GDS platform with payment solutions which allows
                you to manage & distribute contracted inventories, aggregate
                online APIs for flights, hotels etc and distribute the same
                across multiple channels as rest API
              </p>
              <ul>
                <li>
                  <i className="material-icons"></i>
                  Works like a GDS that can be used for various travel products
                </li>
                <li>
                  <i className="material-icons"></i>
                  Channel configuration and management module
                </li>
                <li>
                  <i className="material-icons"></i>
                  Works as a real-time channel manager for subscription & feed
                  distribution
                </li>
                <li>
                  <i className="material-icons"></i>
                  Proven real-world implementation handling 6000+ bookings/day
                </li>
                <li>
                  <i className="material-icons"></i>
                  Integrate multiple flight GDS, LCC API and re-distribute
                  across multiple channels via the unified API
                </li>
                <li>
                  <i className="material-icons"></i>
                  Extensive revenue management and pricing rules engine built in
                  providingranular control on pricing
                </li>
              </ul>
              <span className="home-slider-mobile" style={{ display: "none" }}>
                Customizable GDS platform with payment solutions which allows
                you to manage & distribute contracted inventories, aggregate
                online APIs for flights, hotels etc and distribute the same
                across multiple channels as rest API
              </span>
              <Link
                to="/solutions/inventory-distribution"
                className="btn btn-sm btn-primary"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}

      {current === 5 && (
        <div className="home-slide home-slide1">
          <img className="w-100" src={SliderImg5} alt="Home-Slider" />
          <div className="home-slider-content">
            <div className="container">
              <h2>Travel Technology Consulting</h2>
              <p>
                Our team of experts offers professional guidance on a wide range
                of areas such as digital transformation, distribution, sales &
                marketing, customer retention and more
              </p>
              <ul>
                <li>
                  <i className="material-icons"></i>
                  Holistic analysis of your business processes before kick-off
                </li>
                <li>
                  <i className="material-icons"></i>
                  Identification of areas of improvement and strategic changes
                  required
                </li>
                <li>
                  <i className="material-icons"></i>
                  Assistance with building a clear and effective road map
                  aligned with your goals
                </li>
                <li>
                  <i className="material-icons"></i>
                  Hands-on guidance during development and roll out
                </li>
                <li>
                  <i className="material-icons"></i>
                  Active engagement after going live with personalized workshops
                </li>
              </ul>
              <span className="home-slider-mobile" style={{ display: "none" }}>
                Our team of experts offers professional guidance on a wide range
                of areas such as digital transformation, distribution, sales &
                marketing, customer retention and more
              </span>
              <Link
                to="/solutions/travel-tech-consulting"
                className="btn btn-sm btn-primary"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}

      <button className="prev-btn" onClick={() => handleSlide("prev")}>
        <i className="material-icons"></i>
      </button>
      <button className="next-btn" onClick={() => handleSlide("next")}>
        <i className="material-icons"></i>
      </button>
    </div>
  );
};

export default HomeSlider;
